<template>
  <!-- 行为轨迹 -->
  <div class="box-card" style="text-align: left;margin-top: 20px;">
    <el-form :inline="true" size="small" label-position="right" class="query_form" v-model="quryfrom">
        <el-form-item label="地图类型：">
          <el-select popper-class="my-select" v-model="quryfrom.map_type" placeholder="请选择地图类型" @change="onSubmit">
            <el-option v-for="item in mapTypeOption" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="人员：">
          <el-select popper-class="my-select" clearable v-model="quryfrom.inspector_id" placeholder="请选择人员" filterable
            @change="onSubmit">
            <el-option v-for="item in inspector_personnel_list" :key="item._id" :label="item.user_name"
              :value="item._id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期：" v-if="quryfrom.map_type == 1">
          <el-date-picker value-format="yyyy-MM-dd" v-model="quryfrom.date" type="date" placeholder="请选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="时间：" v-if="quryfrom.map_type == 1">
          <el-time-picker is-range @change="selectTimeChange" v-model="searchtime" range-separator="至"
            start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围">
          </el-time-picker>
        </el-form-item>
        <el-form-item>
          <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
        </el-form-item>
      </el-form>
       <le-card title="行为轨迹">
        <div>
      <el-form v-if="quryfrom.map_type === 1" :inline="true" size="small" label-position="right" class="query_form">
        <el-form-item label="播放速度：">
          <el-select popper-class="my-select" v-model="speedNum" placeholder="请选择播放速度">
            <el-option v-for="item in speedOption" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="warning" size="small" @click="startAnimation">回放</el-button>
        </el-form-item>
      </el-form>
      <div class="map-container" id="map">
      </div>
    </div>
      </le-card>
  </div>
</template>
<script>
import { lazyAMapApiLoaderInstance } from 'vue-amap'
import { getDistributionList, getRingFenceInspectorList, getAllInspector } from '@/api/administrative'
export default {
  data () {
    return {
      mapTypeOption: [{
        value: 1,
        label: '人员轨迹'
      }, {
        value: 2,
        label: '人员分布'
      }],
      markers: [],
      speedOption: [{
        value: 200,
        label: '慢速'
      }, {
        value: 500,
        label: '中速'
      }, {
        value: 1000,
        label: '快速'
      }],
      speedNum: 200,
      searchtime: [new Date(2021, 10, 11, 0, 0, 0), new Date(2021, 10, 11, 23, 59, 59)],
      markerPerson: null,
      passedPolyline: null,
      polygonPoint: [],
      zone_of_action: 0,
      static_rule: 0,
      roadKing: [],
      roadPath: [],
      quryfrom: {
        map_type: 2,
        inspector_id: '',
        date: '',
        start_time: '',
        end_time: ''
      },
      inspector_personnel_list: [],
      allPeople: []
    }
  },
  mounted () {
    this.quryfrom.date = this.validDate(new Date())
    this.requestInspector()
  },
  methods: {
    async requestInspector () {
      const res = await getAllInspector()
      if (res && res.Code === 200) {
        this.inspector_personnel_list = res.Data
        console.log('this.inspector_personnel_list = ', this.inspector_personnel_list)
      }
      this.onSubmit()
    },
    async onSubmit () {
      const params = {
        map_type: this.quryfrom.map_type,
        inspector_id: this.quryfrom.inspector_id,
        date: this.quryfrom.date,
        start_time: this.validTime(this.searchtime[0]),
        end_time: this.validTime(this.searchtime[1])
      }
      console.log('params ===== ', params)
      const res = await getDistributionList(params)
      console.log('getDistributionList-=-=-=-=', JSON.stringify(res))
      if (res.Code !== 200) {
        return
      }
      if (this.quryfrom.map_type === 2) {
        console.log('renruan')
        this.allPeople = res.Data
      } else if (this.quryfrom.map_type === 1) {
        if (res.Data.length === 0) {
          this.$msg.warning('当前人员没有工作轨迹')
          return
        }
        var newFuArray = []
        var that = this
        res.Data.forEach(bigItem => {
          var newChildren = []
          bigItem.sub_table.forEach(item => {
            var index = newChildren.findIndex(find => {
              if (find.longitude === item.longitude && find.latitude === item.latitude && find.is_beyond === item.is_beyond && find.is_static === item.is_static) {
                return true
              }
              return false
            })
            if (index === -1) newChildren.push(item)
          })
          newFuArray.push(newChildren)
        })
        console.log('-=-=-=newFuArray=', JSON.stringify(newFuArray))
        that.roadKing = newFuArray
        that.polygonPoint = res.Data.length > 0 ? res.Data[0].xz_ring_fence : []
        // that.zone_of_action = res.Data.length > 0 ? res.Data[0].xz_ring_fence.zone_of_action : 0;
        // that.static_rule = res.Data.length > 0 ? res.Data[0].xz_ring_fence.static_rule : 0;
      }
      setTimeout(() => {
        this.initMap()
      }, 200)
    },
    selectTimeChange (date) {
      console.log('data = ', date[0])
    },
    startAnimation () {
      console.log('-=-=-=this.roadPath', this.roadPath)
      // console.log('---', this.markerPerson);
      if (this.markerPerson) {
        this.markerPerson.moveAlong(this.roadPath, this.speedNum)
      }
    },
    initMap () {
      const that = this
      lazyAMapApiLoaderInstance.load().then(() => {
        // 1.创建地图实例
        var map = new AMap.Map('map', {
          zoom: 18,
          features: ['bg', 'road', 'building', 'point'] // , "point" 地标太多了显得乱
        })

        if (this.quryfrom.map_type === 2) {
          that.allPeople.filter((data) => data.online_status === 1).map((data) => {
            if (data.longitude !== null && data.latitude !== null) {
              console.log('-=-=-=-=data=', JSON.stringify(data))
              map.setCenter([data.longitude, data.latitude])
              var marker = new AMap.Marker({
                // 获取中心点
                position: [data.longitude, data.latitude],
                content: `<img style="width:38px;height:38px;object-fit: contain;"  src="${require('@/assets/img/inspect_person.png')}"></img>`,
                // offset: new AMap.Pixel(-30, -64),
                // autoRotation: true,
                angle: 0,
                zIndex: 70,
                map: map
              })
              that.markers.push(marker)
              // 3.创建InfoWindow实例
              var infoWindow = new AMap.InfoWindow({
                offset: new AMap.Pixel(8, -30),
                content: `${data.user_name} (${data.phone})`
              })
              // 监听marker的点击事件
              AMap.event.addListener(marker, 'mouseover', function (e) {
                // map.setCenter(marker.getPosition());
                infoWindow.open(
                  map,
                  // 窗口信息的位置
                  marker.getPosition()
                )
              })
              AMap.event.addListener(marker, 'mouseout', function (e) {
                map.clearInfoWindow()
              })
            }
          })
          map.setZoom(10)
        } else if (this.quryfrom.map_type === 1) {
          var polygonList = []
          console.log('-=-=-=-=-=-this.polygonPoint=', JSON.stringify(this.polygonPoint))
          this.polygonPoint.map(trajectoryItem => {
            var path = trajectoryItem.regional_location.map(element => element.split(';'))
            console.log('-=-=-=-=-=-path=', JSON.stringify(path))
            var polygon = new AMap.Polygon({
              path: path,
              strokeColor: '#FF33FF',
              strokeWeight: 6,
              strokeOpacity: 0.2,
              fillOpacity: 0.4,
              fillColor: '#1791fc',
              zIndex: 60
            })
            polygon.setMap(map)
            polygonList.push(polygon)
          })
          var polylinelist = []
          that.roadPath = []
          this.roadKing.map((fu) => {
            console.log('-=-=-fu=', fu)
            var polylineChild = fu.map((item) => [item.longitude, item.latitude])
            var newArray = that.roadPath.concat(polylineChild)
            that.roadPath = newArray
            // console.log("-=-=-=-=that.roadPath=", newArray);
            var polyline = new AMap.Polyline({
              path: polylineChild,
              isOutline: true,
              outlineColor: '#ffeeff',
              borderWeight: 3,
              strokeColor: '#2cad31',
              strokeOpacity: 1,
              strokeWeight: 4,
              // 折线样式还支持 'dashed'
              strokeStyle: 'solid',
              // strokeStyle是dashed时有效
              strokeDasharray: [10, 5],
              lineJoin: 'round',
              lineCap: 'round',
              zIndex: 51
            })
            polyline.setMap(map)
            polylinelist.push(polyline)
          })
          map.setFitView([...polylinelist, ...polygonList])
          // 缩放地图到合适的视野级别
          this.passedPolyline = new AMap.Polyline({
            map: map,
            // path: lineArr,
            strokeColor: '#FFE153', // 线颜色
            // strokeOpacity: 1,     // 线透明度
            strokeWeight: 4, // 线宽
            // strokeStyle: "solid"  // 线样式
            zIndex: 61
          })
          var position = []
          var startPoint = []
          if (this.roadKing && this.roadKing.length > 0) {
            // 开始点
            var startArray = this.roadKing[0]
            if (startArray.length > 0) {
              var itemstart = startArray[0]
              startPoint = [itemstart.longitude, itemstart.latitude]
            }
            // 结束点 也就是当前所在点位
            var newArray = this.roadKing[this.roadKing.length - 1]
            if (newArray.length > 0) {
              var item = newArray[newArray.length - 1]
              position = [item.longitude, item.latitude]
            }
          }

          var endMarker = new AMap.Marker({
            map: map,
            position: position,
            content: `<img style="width:30px;height:38px;object-fit: contain;" src="${require('@/assets/img/end_icon.png')}"></img>`,
            offset: new AMap.Pixel(-16, -38),
            // autoRotation: true,
            angle: 0,
            zIndex: 70
          })
          var startMarker = new AMap.Marker({
            map: map,
            position: startPoint,
            content: `<img style="width:30px;height:38px;object-fit: contain;" src="${require('@/assets/img/start_icon.png')}"></img>`,
            offset: new AMap.Pixel(-16, -38),
            // autoRotation: true,
            angle: 0,
            zIndex: 70
          })

          that.markerPerson = new AMap.Marker({
            map: map,
            position: position,
            content: `<img style="width:38px;height:38px;object-fit: contain;"  src="${require('@/assets/img/inspect_person.png')}"></img>`,
            offset: new AMap.Pixel(-16, -38),
            // autoRotation: true,
            angle: 0,
            zIndex: 70
          })

          that.markerPerson.on('moving', function (e) {
            that.passedPolyline.setPath(e.passedPath)
          })
          this.roadKing.map((fu) => {
            var markerPoint = fu.filter((item) => item.is_beyond || item.is_static)
            markerPoint.map((data) => {
              // 海量生成marker点
              // console.log("-=-=-=data", data);
              var marker = new AMap.Marker({
                position: [data.longitude, data.latitude],
                content: `<img style="width:16px;height:16px;object-fit: contain;"  src="${require('@/assets/img/alarm.png')}"></img>`,
                offset: new AMap.Pixel(-7, -10),
                map: map,
                zIndex: 70
              })
              that.markers.push(marker)
              // 3.创建InfoWindow实例
              var infoWindow = new AMap.InfoWindow({
                offset: new AMap.Pixel(0, -10),
                content: `${warnMsg(data)}`
              })
              function warnMsg (item) {
                if (item.is_beyond) {
                  switch (that.zone_of_action) {
                    case 1:
                      return '区域越界超过300米'
                    case 2:
                      return '区域越界超过500米'
                    case 3:
                      return '区域越界超过1000米'
                    default:
                      return '区域越界'
                  }
                }
                switch (that.static_rule) {
                  case 1:
                    return '静止超过10分钟告警'
                  case 2:
                    return '静止超过30分钟告警'
                  case 3:
                    return '静止超过60分钟告警'
                  default:
                    return '静止告警'
                }
              }
              // 监听marker的点击事件
              AMap.event.addListener(marker, 'mouseover', function (e) {
                // map.setCenter(marker.getPosition());
                infoWindow.open(
                  map,
                  // 窗口信息的位置
                  marker.getPosition()
                )
              })
              AMap.event.addListener(marker, 'mouseout', function (e) {
                map.clearInfoWindow()
              })
            })
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.map-container {
  position: relative;
  width: 100%;
  height: 630px;
}
</style>
